<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <div>
          <el-form-item label="昵称：" prop="nickname">
            <el-input
              placeholder="请输入昵称"
              v-model="tableFrom.nickname"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input
              placeholder="请输入手机号"
              v-model="tableFrom.phone"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关联门店：" prop="storeId">
            <el-select
              v-model="tableFrom.storeId"
              placeholder="请选择"
              class="selWidth"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="姓名：" prop="name">
            <el-input
              placeholder="请输入姓名"
              v-model="tableFrom.name"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="推广员姓名" prop="extensionName">
            <el-input
              placeholder="请输入推广员姓名"
              v-model="tableFrom.extensionName"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item> -->
          <el-form-item label="身份：" prop="identity">
            <el-select
              v-model="tableFrom.identity"
              placeholder="请选择"
              class="selWidth"
              clearable
            >
              <el-option value="1" label="普通用户" />
              <el-option value="2" label="企业会员" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="search()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
            <el-button size="small" @click="exportFunList()">导出</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-card class="mt14">
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号" 
        >
        </el-table-column>
        <el-table-column label="id" prop="userId" min-width="150"/>
        <!-- <el-table-column label="id" prop="userId" min-width="150">
        </el-table-column> -->
        <el-table-column label="头像" min-width="150">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.avatarUrl ? scope.row.avatarUrl : moren"
                :preview-src-list="[scope.row.avatarUrl || moren]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname" min-width="110">
        </el-table-column>
        <el-table-column label="姓名" prop="name" min-width="110">
        </el-table-column>
        <el-table-column label="手机号" prop="phone" min-width="150">
        </el-table-column>
        <el-table-column label="部门名称" prop="deptName" min-width="150">
        </el-table-column>
        <el-table-column label="消费金额" prop="amount" min-width="150">
        </el-table-column>
        <el-table-column label="门店" prop="storeName" min-width="150">
        </el-table-column>
        <!-- <el-table-column label="推广员姓名" prop="extensionName" min-width="100">
        </el-table-column> -->
        <el-table-column prop="extensionAgent" label="是否为推广员" min-width="100">
          <template slot-scope="scope">
            <div>{{  scope.row.extensionAgent==1? `是(${scope.row.extensionCount})`:'否' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="identity" label="身份" min-width="100">
          <template slot-scope="{ row }">
            <span>{{ row.identity == 1 ? "普通用户" : "企业会员" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.cancel_time"
              type="text"
              size="small"
               icon="el-icon-info"
              @click="onDetails(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <!-- <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="image">
          <FormImgUpload
            :url="form.image"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form> -->
    </el-dialog>

    <!--用户详情-->
    <UserDetails
     v-if="drawer"
      @closeDrawer="closeDrawer"
      :drawer="drawer"
      :info="info"
      ref="userDetails"
    />
  </div>
</template>    
<script>
import {
  clientUserList,
  clientUserTemplateExport,
  clientUser,
  clientUserErrorExport,
  clientUserAdd,
  clientUserDetail,
  clientUserUpdate,
  clientUserDel,
  getStoreAllList, //门店接口
} from "@/api/user/list";
import moment from "moment";
import {getIndex} from "@/utils/other";
import { exportExcel } from "@/assets/utils/exportExcel";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import UserDetails from "./userDetails.vue";
export default {
  name: "UserList",
  components: {
    FormImgUpload,
    UserDetails,
  },
  data() {
    return {
      moren: require("@/assets/images/f.png"),
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        nickname: "",
        phone: "",
        time: [],
        storeId: "",
        name: "",
        identity: "",
        extensionName:''
      },
      groupList: [],
      visible: false,
      drawer: false,
      title: "新增用户",
      info: {},
      dialogVisible: false,
      storeList: [], //门店列表
      form: {
        image: [],
        isShow: 0,
        type: 1,
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "部门",
            prop: "deptId",
            type: "cascader",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            checkStrictly: true,
            props: { label: "name", value: "id" },
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "change",
              },
            ],
          },
          {
            label: "姓名",
            prop: "name",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机号",
            prop: "phone",
            span: 20,
            maxlength: 20,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
    this.getStoreList();
  },
  methods: {
    exportFunList() {
      exportExcel("/user/web/clientUser/user/admin/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName:"用户列表"
      });
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done(); 
    },
    upload(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    //获取门店接口
    getStoreList() {
      getStoreAllList({}).then((res) => {
        this.storeList = res.data;
      });
    },
    createUser() {
      this.dialogVisible = true;
      this.getStore();
    },
    //用户详情抽屉框
    onDetails(row) {
      clientUserDetail(row.id).then((res) => {
        this.info = res.data;
        this.drawer = true;
      });
    },
    closeDrawer() {
      this.drawer = false;
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      clientUserList({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    formSubmit() {},
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "@/styles/form.scss";
.check {
  color: #00a2d4;
}
.dia {
  /deep/ .el-dialog__body {
    height: 700px !important;
  }
}
.text-right {
  text-align: right;
}
.minwidth {
  display: inline-block;
  max-width: 200px;
  line-height: 20px;
}
.search-form {
  display: flex;
  justify-content: space-between;
  .search-form-box {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  a {
    color: var(--prev-color-primary);
  }
}
.selWidth {
  width: 250px !important;
}
.search-form-sub {
  display: flex;
}
.container {
  min-width: 821px;
  /deep/.el-form-item {
    width: 100%;
  }
  /deep/.el-form-item__content {
    width: 72%;
  }
}
.vipName {
  color: #dab176;
}
.el-dropdown-link {
  cursor: pointer;
  color: var(--prev-color-primary);
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}
/deep/.el-date-editor.el-input {
  width: 100%;
}
/deep/[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none !important;
}
/deep/.el-input-group__prepend .el-input {
  width: 100px;
}
</style>
    