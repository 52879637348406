<template>
  <div>
    <el-drawer
      :with-header="false"
      :size="1000"
      :visible.sync="drawer"
      :before-close="handleClose"
    >
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="用户详情">
          <div class="head">
            <div class="full">
              <img class="order_icon" :src="info.avatarUrl || moren" alt="" />
              <div class="text">
                <div class="title">{{ info.nickname }}</div>
              </div>
            </div>
            <div class="section">
              <!-- <div class="title">用户信息</div> -->

              <ul class="list">
                <li class="item">
                  <div class="item-title">id：</div>
                  <div class="value">{{ info.userId || "-" }}</div>
                </li>
                <li class="item">
                  <div class="item-title">昵称：</div>
                  <div class="value">{{ info.nickname || "-" }}</div>
                </li>
                <li class="item">
                  <div class="item-title">姓名：</div>
                  <div class="value">{{ info.name || "-" }}</div>
                </li>
                <li class="item">
                  <div class="item-title">手机号：</div>
                  <div class="value">{{ info.phone || "-" }}</div>
                </li>
                <li class="item">
                  <div class="item-title">身份：</div>
                  <div class="value">
                    {{ info.identity == 1 ? "普通用户" : "企业会员" }}
                  </div>
                </li>
                <li class="item">
                  <div class="item-title">门店：</div>
                  <div class="value">{{ info.storeName }}</div>
                </li>
                <li class="item">
                  <div class="item-title">消费金额：</div>
                  <div class="value">{{ info.amount }}</div>
                </li>
                <li class="item">
                  <div class="item-title">注册方式：</div>
                  <div class="value">
                    {{ info.registerType == 1 ? "导入" : "游客" }}
                  </div>
                </li>

                <li class="item">
                  <div class="item-title">性别：</div>
                  <div class="value">{{ info.sex == 1 ? "男" : "女" }}</div>
                </li>
                <li class="item">
                  <div class="item-title">是否为推广员：</div>
                  <div class="value">{{ info.extensionAgent==1?'是':'否' }}</div>
                </li>
                <li class="item">
                  <div class="item-title">注册时间：</div>
                  <div class="value">{{ info.createTime }}</div>
                </li>
              </ul>
              <ul v-if="info.extensionAgent==1" class="list">
                <li   @click="downloadIamge(info.extensionCode,info.name)" class="download item">
                  下载分享二维码
                </li>
              </ul>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分列表">
          <div style="padding: 10px">
            <el-card class="box-card">
              <el-table :data="info.integralList" size="small">
                 <el-table-column type="index" width="100" label="序号">
                </el-table-column>
                <!-- <el-table-column prop="id" label="id" width="300" /> -->
                <el-table-column prop="name" label="积分账户名称" />
                <el-table-column prop="integral" label="剩余积分" />
                <el-table-column prop="type" label="账户类型">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.type == 1
                        ? "全部商品可用"
                        : scope.row.type == 2
                        ? "部分商品可用"
                        : "部分分类可用"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="validityDate" label="有效期" />
              </el-table>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分流水详情">
          <IntegralTalel :id="info.id" />
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>

<script>
// import { userOrderApi, userDetailApi, userCouponApi, userBillApi,
//   modifyUserRefLog, userPointsApi, userSignLogApi, userHistoryApi, memberGrowthLog, userEditApi } from '@/api/user'
// import { verifyEmail } from '@/utils/toolsValidate';
import IntegralTalel from "./components/integralTalel.vue";
export default {
  name: "UserDetails",
  components: {
    IntegralTalel,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      moren: require("@/assets/images/f.png"),
      activeName: "",
      dialogVisible: false,
      id: "",
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("closeDrawer");
    },
    onDetails(row) {
      this.id = row.id;
      this.dialogVisible = true;
    },
    // 下载分享二维码
    downloadIamge(imgsrc, name) {
      var image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png");

        var a = document.createElement("a");
        var event = new MouseEvent("click");
        a.download =`${name}推广二维码` || "photo";
        a.href = url;
        a.dispatchEvent(event);
      };
      image.src = imgsrc; // 指定图片的来源，这是需要我们提供的
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 33%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;
  &:before {
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title {
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }
  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img {
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th {
  background: #f0f5ff;
}
.download{
  cursor: pointer;
  color:cornflowerblue !important;
}
</style>
