import { get, put } from '../axios'

// 用户管理-列表
export const userManageList = params => get(`/user/web/user/manage/list`,params);

// 用户管理-状态修改
export const userManageStateUpdate = params => put(`/user/web/user/manage/state/update`,params);


//用户反馈-列表
export const userFeedbackList= params => get(`/user/web/userFeedback/list`,params);

//用户反馈-查看
export const userFeedbackDetial= params => get(`/user/web/userFeedback/view/${params}`); 

//用户反馈-查看
export const getIntegralFlowDetail = params => get(`/user/web/clientUser/integralFlowDetail`,params);

