<template>
  <div>
    <el-button type="primary" size="mini" @click="exportsFun" style="margin-bottom: 10px;">导出积分</el-button>
    <el-table v-loading="listLoading" :data="tableData.data" size="small">

      <el-table-column
          type="index"
          width="80"
          label="序号"
        />
      <el-table-column
        prop="accountName"
        label="积分账户名称"
        min-width="100"
      />
      <el-table-column prop="description" label="备注" min-width="100" />
      <el-table-column prop="integral" label="积分" min-width="100" />
      <el-table-column prop="relNo" label="关联单号" min-width="100" />
      <el-table-column prop="source" label="来源" min-width="100">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.source == 0
                ? "系统调整"
                : scope.row.source == 1
                ? "订单消费"
                : "订单退回"
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="type" label="扣除方式" min-width="100">
        <template slot-scope="scope">
          <div>
            {{ scope.row.type == 1 ? "扣减" : "增加" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="操作时间" min-width="100" />
      <el-table-column prop="operator" label="操作员" min-width="100" />
    </el-table>
    <div class="block">
      <el-pagination
        background
        :page-size="tableFrom.pageSize"
        :current-page="tableFrom.page"
        layout="total, prev, pager, next, jumper"
        :total="tableData.total"
        @size-change="handleSizeChange"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script>
import { getIntegralFlowDetail } from "@/api/user/user";
import { exportExcel } from "@/assets/utils/exportExcel";
export default {
  name: "integralTalel",
  props: {
    id: {
      type: String,
      default: false,
    },
  },
  data() {
    return {
      activeName: "1",
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      brandList: [], //品牌列表
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    searchReset() {
      this.tableFrom.name = "";
      this.getList();
    },
    onDetails(row) {
      this.$emit("getGoodsItem", row);
    },
    exportsFun(){
      exportExcel("/web/clientUser/integralFlowDetail/export", {
        ...this.tableFrom,
        time: "",
        userId:this.id,
        pageNum: 1,
        pageSize:1000,
        responseType: "blob",
        exportExcelName:"用户积分流水详情"
      });
    },
    getList() {
      this.listLoading = true;
      getIntegralFlowDetail({ ...this.tableFrom, userId: this.id })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style scoped>
</style>
